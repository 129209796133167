<template>
	<div class="page container">
		<div>
			<activity
				:isCreate="true"
				:type="getType"
				:lessonId="getLessonId"
				:cancelRoute="getLessonRoute"
				:successRoute="getLessonRoute"
			/>
		</div>
	</div>
</template>

<script>

	import Activity           from '@/components/activities/edit/Activity';
	import routeParams        from '@/mixins/routeParams';
	import { activityTypes }  from '@/consts';

	export default {
		layout: 'v2default',
		metaInfo () {
			if (!this.getTitle) {
				return false;
			}
			return {
				title: this.getTitle
			};
		},
		components: {
			Activity
		},
		mixins: [routeParams],
		computed: {
			getType () {
				return this.$route.query.type;
			},
			getTitle () {
				const type = activityTypes.find((activity) => {
					return this.getType === activity.value;
				}).text;
				return `New ${type.toLowerCase()}`;
			},
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/teaching/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getCourseRoute ||
						!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/${this.getLessonId}`;
			}
		},
		created () {
			this.$store.commit('ui/setLoadingIsHidden');
		}
	};

</script>
